<template>
  <base-projects
    :projects="dueProjects"
    :is-loading="!dueProjects.length && isLoading.projects"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import BaseProjects from './BaseProjects';

export default {
  components: { BaseProjects },
  computed: {
    ...mapGetters([
      'dueProjects',
      'isLoading'
    ])
  }
};
</script>
